import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Api} from "../../services/api";
import Carousel from "../../components/Carousel";
import CardPrimary from "../../components/CardContainer";
import {banner, bannerMobile, filter} from "../../assets/icons";
import {useNavigate} from "react-router-dom";
import FilterComponent from "../../components/Filter";
import LoadingOverlay from "../../components/LoadingOverlay";

const Home = () => {
    const { t } = useTranslation(['home', 'main']);
    let history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [typeVehicles, setTypeVehicles] = useState([]);
    const [filterMileage, setFilterMileage] = useState(0);
    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState<any[]>([]);
    const [filterManufacturer, setFilterManufacturer] = useState<any[]>([]);

    const getVehicles = () => {
        setLoading(true);
        let url = '/list-vehicles?';
        if (filterName) {
            url = `${url}name=${filterName}&`;
        }
        if (filterType.length > 0) {
            url = `${url}type=${filterType.toString()}&`;
        }
        if (filterManufacturer.length > 0) {
            url = `${url}manufacturer=${filterManufacturer.toString()}&`;
        }
        Api.get(url)
            .then(response => {
                setVehicles(response.data.vehicles);
                setManufacturers(response.data.manufacturers);
                setTypeVehicles(response.data.typeVehicles);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getVehicles();
    }, []);

    const changeInput = (e: any, key: string) => {
        const {checked, id} = e.currentTarget;

        if (key === 'manufacturer') {
            const list = [...filterManufacturer];
            const cleanId = id.replace('manufacturer-', '');
            if (checked) {
                list.push(cleanId);
            } else {
                const index = list.findIndex(value => value === cleanId);
                if (index > -1) list.splice(index, 1);
            }
            setFilterManufacturer(list);
        }

        if (key === 'type-vehicle') {
            const list = [...filterType];
            const cleanId = id.replace('type-vehicle-', '');
            if (checked) {
                list.push(cleanId);
            } else {
                const index = list.findIndex(value => value === cleanId);
                if (index > -1) list.splice(index, 1);
            }
            setFilterType(list);
        }
    };

    return (
        <div className="container-fluid pt-4">
            <div className="row">
                <div className="col-12 d-none d-lg-flex justify-content-center my-2">
                    <img className="img-fluid w-100 px-4" src={banner} alt="banner" />
                </div>
                <div className="col-12 d-lg-none d-flex justify-content-center my-2">
                    <img className="img-fluid w-100" src={bannerMobile} alt="banner" />
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <Form className="d-flex w-100 p-sm-0 p-lg-4">
                        <Form.Control
                            value={filterName}
                            type="search"
                            placeholder={t('home.search.placeholder')}
                            className="me-2"
                            aria-label="Search"
                            size="lg"
                            onChange={(e) => setFilterName(e.currentTarget.value)}
                        />
                        <Button variant="outline-success" onClick={getVehicles}>
                            {t('home.search.button')}
                        </Button>
                    </Form>
                </div>
            </div>
            <div className="row mt-4 px-4">
                <div className="col-lg-2 d-none d-lg-block py-2" style={{height: '80vh', overflowY: 'auto'}}>
                    <FilterComponent
                        manufacturers={manufacturers}
                        typeVehicles={typeVehicles}
                        changeInput={changeInput}
                        getVehicles={getVehicles}
                        t={t}
                    />
                </div>
                <div className="col-lg-10 col-sm-12">
                    {loading && <LoadingOverlay />}
                    <div className="row">
                        {vehicles.map((value: any) => (
                            <div className="col-lg-4 col-sm-12 my-2" key={value.id}>
                                <CardPrimary
                                    titleDescription={value.name}
                                    bodyDescription={t('home.card.description', { price: value?.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) })}
                                    headerChildren={<Carousel interval={3000} item={value.vehicle_images}/>}
                                    textButton={t('home.card.button')}
                                    callBack={() => history(`/description/${value.id}`)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;