import './App.css';
import {BrowserRouter} from "react-router-dom";
import AppRoutes from "./routes";
import GlobalCSS from './global.css'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';


function App() {
    return (
        <div className="App">
            <GlobalCSS/>
            <BrowserRouter>
                <AppRoutes/>
                <ToastContainer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
