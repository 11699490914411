import { createGlobalStyle } from 'styled-components'
export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: ${(props: { darkMode?: any; }) => (props.darkMode ? 'white' : 'black')};
  }
  .logo {
    width: 100px;
  }
  .header-height {
    height: 56px;
  }
  .main-height {
    min-height: calc(100vh - 56px);
  }
  .bg-blue-primary {
    background: #0A3161 !important;
  }
  .bg-red-primary {
    background: #B31942 !important;
  }

  .whatsapp-link {
    filter: invert(74%) sepia(19%) saturate(1821%) hue-rotate(84deg) brightness(92%) contrast(82%);
  }
  .icon-size-30 {
    width: 30px;
  }
  .card-min-height {
    min-height: 500px;
  }
  card-header-min-height {
    min-height: 250px;
  }
  .font-filter {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5px;
  }
  .secondary-label-color {
    label {
      color: #0000008a;
    }
  }
  .font-600 {
    font-weight: 600
  }
  .mobile-none {
    @media (max-width: 480px) {
      display: none !important;
    }
  }
`