import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./translation/English/translate.json";
import translationPortuguese from "./translation/Portuguese/translate.json";

const resources = {
    en: {
        home: translationEnglish,
    },
    ptBr: {
        home: translationPortuguese,
    },
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng:"en",
    });

export default i18next;