import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Carousel from "../../components/Carousel";
import {Api} from "../../services/api";
import {whatsapp} from "../../assets/icons";
import LoadingOverlay from "../../components/LoadingOverlay";

interface Vehicle {
    name: string;
    vehicle_images: any[];
    mileage: number;
    model_year: string;
    color: string;
    vin_number: string;
    autonomy_in_the_city: number;
    autonomy_on_the_highway: number;
    description: string;
    model: string;
}

const Description = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [vehicle, setVehicle] = useState<Vehicle>();

    const getVehicles = () => {
        setLoading(true);
        let url = `/list-vehicles?id=${id}`;
        Api.get(url)
            .then(response => setVehicle(response.data.vehicles[0]))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getVehicles();
    }, [id]);

    return (
        <div className="container-fluid p-3">
            {loading && <LoadingOverlay />}
            <div className="row py-5">
                <div className="col-lg-6 col-md-12 d-flex justify-content-center mb-4">
                    <div className="w-100 shadow-sm p-3" style={{ maxWidth: '500px', border: '1px solid #ddd', borderRadius: '5px' }}>
                        <Carousel interval={3000} item={vehicle?.vehicle_images || []} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="col-12 mb-4">
                        <h3 className="font-weight-bold" style={{ fontSize: '1.8rem' }}>{vehicle?.name}</h3>
                        <p className="text-secondary" style={{ fontSize: '1rem' }}>{vehicle?.description}</p>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <p className="font-weight-bold mb-1" style={{ fontSize: '1.1rem' }}>Quilometragem:</p>
                            <p style={{ fontSize: '1rem' }}>{vehicle?.mileage} km</p>
                        </div>
                        <div className="col-6">
                            <p className="font-weight-bold mb-1" style={{ fontSize: '1.1rem' }}>Ano:</p>
                            <p style={{ fontSize: '1rem' }}>{vehicle?.model_year}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <p className="font-weight-bold mb-1" style={{ fontSize: '1.1rem' }}>Cor:</p>
                            <p style={{ fontSize: '1rem' }}>{vehicle?.color}</p>
                        </div>
                        <div className="col-6">
                            <p className="font-weight-bold mb-1" style={{ fontSize: '1.1rem' }}>Modelo:</p>
                            <p style={{ fontSize: '1rem' }}>{vehicle?.model}</p>
                        </div>
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '1.1rem' }}>Autonomia:</p>
                        {vehicle?.autonomy_in_the_city && (
                            <p style={{ fontSize: '1rem' }}>Na cidade: {vehicle?.autonomy_in_the_city} km/l</p>
                        )}
                        {vehicle?.autonomy_on_the_highway && (
                            <p style={{ fontSize: '1rem' }}>Na rodovia: {vehicle?.autonomy_on_the_highway} km/l</p>
                        )}
                    </div>
                    <div className="d-flex justify-content-start">
                        <a href="https://api.whatsapp.com/send?phone=17743689099&text=Ol%C3%A1%20tenho%20interesse%20em%20adquirir%20um%20ve%C3%ADculo%20"
                           target="_blank"
                           className="btn btn-primary d-flex align-items-center text-decoration-none text-white"
                           style={{ fontSize: '1rem', padding: '10px 20px' }}>
                            <span className="me-2">Tenho interesse</span>
                            <img className="whatsapp-link" src={whatsapp} alt="whatsapp"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Description;