import {FC, ReactElement} from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {whatsapp} from "../../assets/icons";

interface Props {
    headerChildren?: ReactElement;
    titleDescription: string;
    bodyDescription?: string;
    textButton?: string;
    callBack: () => void;
}
const CardPrimary:FC<Props> = ({headerChildren, titleDescription, bodyDescription, textButton, callBack}) =>  {
    return (
        <Card className="border-0 shadow-lg card-min-height">
            <Card.Header className="p-0 border-0">
                {headerChildren && headerChildren}
            </Card.Header>
            <Card.Body>
                <Card.Title>{titleDescription}</Card.Title>
                <Card.Text>
                    {bodyDescription && bodyDescription}
                </Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between border-0 bg-transparent">
                <Button
                    variant="primary"
                    className="bg-blue-primary border-0"
                    type="button"
                    onClick={() => callBack()}
                >
                    {textButton}
                </Button>
                <a className="whatsapp-link" href="https://api.whatsapp.com/send?phone=17743689099&text=Ol%C3%A1%20tenho%20interesse%20em%20adquirir%20um%20ve%C3%ADculo%20" target="_blank">
                    <img src={whatsapp} alt="whatsapp"/>
                </a>
            </Card.Footer>
        </Card>
    );
}

export default CardPrimary;