import Spinner from 'react-bootstrap/Spinner';

const LoadingOverlay = () => (
    <div style={{
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 9999
    }}>
        <Spinner animation="border" variant="primary" />
    </div>
);

export default LoadingOverlay;