import Navbar from "../components/Navbar";
import {Outlet} from "react-router-dom";
import {instagram} from "../assets/icons";

const Layout = () => {
    return (
        <div>
            <Navbar />
            <main className="bg-light main-height">
                <Outlet />
            </main>

            <footer className="text-center text-lg-start"
                    style={{backgroundColor: 'var(--primary-color-dark)', color: 'var(--text-color-light)'}}>
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
                         style={{borderColor: 'var(--border-color-light)'}}>
                    <div>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-google"></i>
                        </a>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="" className="me-4 text-reset" style={{color: 'var(--text-color-light)'}}>
                            <i className="fab fa-github"></i>
                        </a>
                    </div>
                </section>

                <section className="text-center text-md-start mt-5">
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4"
                                    style={{color: 'var(--secondary-color-soft)'}}>
                                    <i className="fas fa-gem"></i> Cape Motors
                                </h6>
                                <p>
                                    We believe that everyone deserves a fair deal when it comes to purchasing their
                                    dream car.
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4"
                                    style={{color: 'var(--secondary-color-soft)'}}>Contact</h6>
                                <p>
                                    <i className="fas fa-home me-3"></i>
                                    <a className="text-decoration-none" style={{color: 'var(--text-color-light)'}}
                                       href="https://goo.gl/maps/jodCgxX6MTr9m5qt8"
                                       target="_blank"
                                    >
                                        20 Bearse Road, Hyannis, MA 02601
                                    </a>
                                </p>
                                <p>
                                    <a className="text-decoration-none" style={{color: 'var(--text-color-light)'}}
                                       href="https://www.instagram.com/capemotorsinc/" target="_blank">
                                        <img className="ms-3 me-2" src={instagram} alt="insta" width={16}/>
                                        @capemotorsinc
                                    </a>
                                </p>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    <a className="text-decoration-none" style={{color: 'var(--text-color-light)'}}
                                       href="mailto:info@capemotorsinc.com"
                                       target="_blank"
                                    >
                                        info@capemotorsinc.com
                                    </a>
                                </p>
                                <p><i className="fas fa-phone me-3"></i> 508-775-3700</p>
                                <p><i className="fas fa-print me-3"></i> 774-368-9099</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center p-4"
                     style={{backgroundColor: 'var(--primary-color-dark)', color: 'var(--text-color-light)'}}>
                    © 2024 Copyright
                </div>
            </footer>
        </div>
    );
}

export default Layout;