import React from 'react';
import { Card, Form, Button } from 'react-bootstrap';

const FilterComponent = ({ manufacturers, typeVehicles, changeInput, getVehicles, t }) => {
    return (
        <Card style={{ width: '100%', padding: '10px' }} className="h-100 border shadow-sm">
            <Card.Header className="d-flex bg-transparent border-0">
                <h5 className="m-auto">
                    <i className="bi bi-funnel-fill"></i> {t('home.filter.title')}
                </h5>
            </Card.Header>
            <Card.Body>
                <Form>
                    <h6>{t('home.filter.manufacturers')}</h6>
                    {manufacturers.map((value) => (
                        <Form.Check
                            key={value.id}
                            className="mb-1 secondary-label-color"
                            type="checkbox"
                            id={`manufacturer-${value.id}`}
                            label={value.name}
                            onChange={(event) => changeInput(event, 'manufacturer')}
                        />
                    ))}
                    <hr />
                    <h6>{t('home.filter.type')}</h6>
                    {typeVehicles.map((value) => (
                        <Form.Check
                            key={value.id}
                            className="mb-1 secondary-label-color"
                            type="checkbox"
                            id={`type-vehicle-${value.id}`}
                            label={value.name}
                            onChange={(event) => changeInput(event, 'type-vehicle')}
                        />
                    ))}
                    <Button variant="outline-success" className="mt-3 w-100" type="button" onClick={getVehicles}>
                        {t('home.search.button')}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default FilterComponent;