import { Routes, Route } from "react-router-dom"
import Layout from "./layouts";
import Home from "./pages/Home";
import Description from "./pages/Description";

const AppRoutes = () => {

    return (
        <Routes>
            <Route element={<Layout />} >
                <Route path="/" element={<Home />} />
                <Route path="/description/:id" element={<Description />} />
            </Route>
        </Routes>
    )
}
export default AppRoutes;