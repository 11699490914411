import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {logo} from "../../assets/images";
import {brazil, eua} from "../../assets/icons";
import {changeLanguage} from "i18next";

function AppNavbar() {
    const { t } = useTranslation(['home', 'main']);

    return (
        <Navbar expand="lg" className="bg-white shadow header-height">
            <Container fluid className="px-0">
                <Navbar.Brand href="/" className="ms-2">
                    <img src={logo} className="logo" alt="Cape-motors"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" className="me-2" />
                <Navbar.Collapse id="navbarScroll" className="bg-white z-1">
                    <Nav
                        className="me-auto my-2 my-lg-0 d-lg-flex d-none"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href="/">{t('header.home')}</Nav.Link>
                        {/*<Nav.Link href="#action2">{t('header.vehicles')}</Nav.Link>*/}
                        {/*<Nav.Link href="#action3">{t('header.simulation')}</Nav.Link>*/}
                        {/*<Nav.Link href="#action4">{t('header.contacts')}</Nav.Link>*/}
                    </Nav>
                    <button className="bg-transparent border-0"
                            type="button"
                            onClick={() => changeLanguage('ptBr')}
                    >
                        <img src={brazil} alt="ptbr"/>
                    </button>
                    <button className="bg-transparent border-0"
                            type="button"
                            onClick={() => changeLanguage('en')}
                    >
                        <img src={eua} alt="english"/>
                    </button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavbar;