import Carousel from 'react-bootstrap/Carousel';
import {FC} from "react";

interface CarouselProps {
    item: any[],
    interval: number;
}
const IndividualIntervalsExample:FC<CarouselProps> = ({item, interval}) => {
    const PATH = 'https://cape-motors.s3.sa-east-1.amazonaws.com/uploads/';
    return (
        <Carousel>
            {item.map((value: any) => (
                <Carousel.Item interval={interval} key={value.name}>
                    <img
                        className="d-block w-100 img-fluid"
                        src={`${PATH}${value.name}`}
                        alt="Photo"
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default IndividualIntervalsExample;